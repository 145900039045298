<template>
  <div>
    <b-card header="Edit Session">
      <spinner-loader :loading="loading" />

      <session-form
        v-if="!loading"
        :submit="editSession"
        :session.sync="session"
        :event="event"
      />
    </b-card>
  </div>
</template>

<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import SessionForm from '@/common/components/QuickEvents/Sessions/SessionForm.vue'
import SpinnerLoader from '@/common/components/common/Table/SpinnerLoader.vue'
import Session from '@/common/compositions/QuickEvents/sessionApi'

export default {
  name: 'EditSession',
  components: {
    SessionForm,
    SpinnerLoader,
  },
  data() {
    return {
      event: {},
      loading: true,
    }
  },
  setup() {
    const { session, getSessionRequest, editSessionRequest } = Session()
    const { successfulOperationAlert } = handleAlerts()
    return {
      session, getSessionRequest, editSessionRequest, successfulOperationAlert,
    }
  },
  created() {
    this.getSession()
  },
  methods: {
    getSession() {
      return this.getSessionRequest(this.$route.params.eventId, this.$route.params.id).then(res => {
        const { session, event } = res.data.data

        this.session = {
          ...session,
          language: session.language_id,
          paidTicket: {
            paid: session.paid,
            price: session.price,
            tax_ratio: session.tax_ratio,
            charity_deduction_type: session.charity_deduction_type,
            charity_deduction_amount: session.charity_deduction_amount,
            donation_deduction_type: session.donation_deduction_type,
            donation_deduction_amount: session.donation_deduction_amount,
            payment_method: session.payment_method,
            additional_services: session.price ? session.additional_services : [],
            user_covers_comissions: session.user_covers_comissions,
            charity_id: session.charity_id,
          },
        }

        this.event = {
          startTime: event.start_date,
          endTime: event.end_date,
          presenceType: event.presence_type,
          singleSession: event.singleSession,
        }
      }).finally(() => {
        this.loading = false
      })
    },
    editSession() {
      return this.editSessionRequest(this.$route.params.eventId, this.$route.params.id).then(() => {
        this.successfulOperationAlert('Session is updated successfully')
        this.$router.push({ name: 'quick-event-sessions-list' })
      })
    },
  },
}
</script>

<style lang="scss" scoped>
</style>
