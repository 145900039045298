<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>
      <session-inputs
        :session="session"
        :event="event"
      />
      <!-- Submit -->
      <b-form-row class="mt-1">
        <back />

        <submit-button
          :disabled="!segmentsCapacityValid"
          class="ml-auto"
          v-bind="{submit,handleSubmit}"
        />
      </b-form-row>

    </b-form>
  </validation-observer>
</template>
<script>
import SubmitButton from '@/common/components/common/FormInputs/SubmitButton.vue'
import Back from '../../common/FormInputs/Back.vue'
import SessionInputs from './SessionInputs.vue'

export default {
  name: 'SessionForm',
  components: {
    SubmitButton,
    Back,
    SessionInputs,
  },
  props: {
    session: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
    event: { type: Object, default: () => {} },
  },
  data() {
    return {
      segmentsCapacityValid: true,
    }
  },
  provide() {
    return {
      segmentsCapacityValid: this.segmentsCapacityValid,
    }
  },
}
</script>
<style lang="scss">

</style>
